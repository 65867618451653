import ChevronIconImg from "images/chevron-up.svg";
import DownloadIconImg from "images/ic_file_download.svg";
import { FILE_STATUS_PROMOTION_FAILED } from "common/constants";
import { FILE_STATUS_SUBMISSION_PROBLEM } from "common/constants";
import HelpIconImg from "images/ic_info.svg";
import HyperLink from "../HyperLink/HyperLink";
import PromotionModal from "./PromotionModal/PromotionModal";
import { downloadFileFromS3 } from "common/utils/utils";
import { promotionFileStages } from "./ImportTable.constant";
import { redirectToViewImport } from "common/utils/utils";
import { useState } from "react";

const ImportTableBodyActions = ({
  refreshImports,
  hidePromoteLink,
  isViewerRoleUser,
  importFile,
}) => {
  const isCorrectRecordCount =
    importFile?.clean_records > 0 ||
    (importFile?.clean_records === 0 && importFile?.excluded_records > 0);

  const showPromoteLink =
    promotionFileStages.includes(importFile?.import_status) &&
    isCorrectRecordCount &&
    !isViewerRoleUser &&
    !hidePromoteLink;

  return (
    <>
      <HyperLink
        iconSrc={HelpIconImg}
        altText="Details"
        text="Details"
        onClick={() => redirectToViewImport(importFile?.id)}
      />
      {importFile?.is_error_present &&
        importFile?.import_status !== FILE_STATUS_SUBMISSION_PROBLEM && (
          <DownloadErrorReportLink
            errorReportPath={importFile?.error_report_path}
          />
        )}
      {showPromoteLink && (
        <PromoteDataLink file={importFile} refreshImports={refreshImports} />
      )}
    </>
  );
};

export default ImportTableBodyActions;

//***************************/
//*****Local Components******/
//***************************/

const DownloadErrorReportLink = ({ errorReportPath }) => {
  return (
    <HyperLink
      onClick={(event) => {
        event.stopPropagation();
        downloadFileFromS3(errorReportPath);
      }}
      iconSrc={DownloadIconImg}
      altText="Download"
      text="Error Report"
    />
  );
};

const PromoteDataLink = ({ file, refreshImports }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isPromotionFailed =
    file?.import_status === FILE_STATUS_PROMOTION_FAILED;
  const promoteLinkText = isPromotionFailed ? "Retry Promote" : "Promote";

  const handlePromoteClick = (event) => {
    // * To stop propogating parent's on click
    event.stopPropagation();
    setIsModalOpen(true);
  };

  return (
    <>
      <span
        title={
          isPromotionFailed
            ? "Contact dev to retry promote \nInternal server issue"
            : ""
        }>
        <HyperLink
          iconSrc={ChevronIconImg}
          altText="Up"
          text={promoteLinkText}
          onClick={handlePromoteClick}
          disabled={isPromotionFailed}
        />
      </span>
      <PromotionModal
        {...{
          isModalOpen,
          setIsModalOpen,
          numberOfCleanRecords: file?.clean_records,
          numberOfExcludedRecords: file?.excluded_records,
          fileId: file?.id,
          partnerId: file?.partner_id,
          refreshImports,
        }}
      />
    </>
  );
};
