import * as styles from "styles/CommonDropdownStyles.module.scss";
import { useState } from "react";

import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";
import { PAGE_IDENTIFIERS } from "common/constants";
import {
  clearCleanDataUpdateImportFiters,
  // clearImportFilters,
} from "common/utils/utils";
import { useAutoClearFilter } from "common/hooks/useAutoClearFitlers";

import DateRangeFilter from "components/common/DateRangeFilter/DateRangeFilter";
import CleanDataUpdateImportStatusDropdown from "./CleanDataUpdateImportStatusDropdown/CleanDataUpdateImportStatusDropdown";
// import ImportTypeDropdown from "./ImportTypeDropdown/ImportTypeDropdown";
import PartnerDropdown from "components/common/PartnerDropdown/PartnerDropdown";
import Button from "components/common/Button/Button";

const CollapsibleFilterChildren = ({ searchOptions, setSearchOptions }) => {
  // const [importType, setImportType] = useState(null);
  const [cleanDataUpdateImportStatus, setCleanDataUpdateImportStatus] =
    useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const pageIdentifier = PAGE_IDENTIFIERS.CLEAN_DATA_IMPORT_LIST;
  const buttonTitle = "Clear Filter";

  /**
   * The function `clearFilters` clears all filters and search options for a given page identifier.
   *
   * @param pageIdentifier - The `pageIdentifier` parameter is used to identify the page for which the
   * import filters are being cleared.
   */
  const clearFilters = (pageIdentifier) => {
    clearCleanDataUpdateImportFiters(pageIdentifier);

    setSearchOptions({});
    // setImportType(null);
    setCleanDataUpdateImportStatus(null);
    setStartDate("");
    setEndDate("");
  };

  /* To automatically clear import filters for a given page identifier. */
  useAutoClearFilter(pageIdentifier, clearCleanDataUpdateImportFiters);

  return (
    <div className="d-flex gap-4">
      <PartnerDropdown
        {...{
          selectedPartner: searchOptions?.partner,
          searchOptions,
          setSearchOptions,
          className: styles.Dropdown,
          pageIdentifier,
        }}
      />
      <CleanDataUpdateImportStatusDropdown
        {...{
          cleanDataUpdateImportStatus,
          setCleanDataUpdateImportStatus,
          setSearchOptions,
          className: styles.Dropdown,
          pageIdentifier,
        }}
      />
      <DateRangeFilter
        {...{
          startDate,
          endDate,
          setStartDate,
          setEndDate,
          setSearchOptions,
          className: styles.Date_Picker,
          placeholder: "Imported between",
          pageIdentifier,
        }}
      />
      <Button
        {...{
          buttonType: ButtonTypes.PRIMARY_SMALL,
          buttonContentType: ButtonContentTypes.TEXT,
          onClick: () => clearFilters(pageIdentifier),
          title: buttonTitle,
        }}>
        <span>{buttonTitle}</span>
      </Button>
    </div>
  );
};

export default CollapsibleFilterChildren;
