import * as countTableStyles from "components/common/CountTable/CountTable.module.scss";
import * as cleanDataUpdateImportDetailStyles from "../CleanDataUpdateImportDetail.module.scss";

import { redirectToViewImport } from "common/utils/utils";

import classNames from "classnames";
import OverviewHeader from "./OverviewHeader";
import OverviewDetail from "./OverviewDetail";

const Overview = ({ details, title, type, className }) => {
  const {
    label,
    file_name: fileName,
    created_at: createdAt,
    id,
    file_path: filePath,
    created_by: createdBy,
    // file_size: fileSize,
    partner_name,
    // promoted_by_name: promotedByName,
    // promoted_at: promotedAt,
    // error_report_path: errorReportPath,
    import_status: importStatus,
    updated_columns: updatedColumns,
  } = details;

  const sectionClasses = classNames(
    countTableStyles.Count_Table_Card_Container,
    cleanDataUpdateImportDetailStyles.Import_Card_Container,
    className
  );

  return (
    <section
      className={sectionClasses}
      onClick={() => type !== "main" && redirectToViewImport(id)}>
      <OverviewHeader {...{ title, type }} />
      <OverviewDetail
        {...{
          label,
          fileName,
          createdAt,
          filePath,
          // fileSize,
          // userName,
          type,
          partner_name,
          // promotedByName,
          // promotedAt,
          // errorReportPath,
          importStatus,
          // fileColumns,
          createdBy,
          updatedColumns,
        }}
      />
    </section>
  );
};

export default Overview;
