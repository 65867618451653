import * as styles from "styles/Common.module.scss";

import { getErrorMessage } from "common/utils/errorUtils";
import { useEffect, createRef } from "react";
import { useInfiniteQuery, useQueryClient } from "react-query";

import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import CustomLoader from "../CustomLoader/CustomLoader";
import NoDataFound from "../NoDataFound/NoDataFound";
import ImportTableBody from "./ImportTableBody";
import ImportTableHeader from "./ImportTableHeader";
import classNames from "classnames";
import {
  storeScrollPosition,
  fetchStoredScrollPosition,
} from "common/utils/utils";

const ImportTable = ({
  searchOptions,
  setAreOptionsDisabled,
  currentDashboard,
  partnerId,
  fetchImports,
  hidePromoteLink,
  extraClass,
}) => {
  // * #####################################
  // * Effects
  // * #####################################

  const importTableRef = createRef();

  const tableClasses = classNames(
    "Table_Wrapper",
    styles[extraClass],
    styles.List_Scroll_Table
  );
  const containerClasses = { overflow: "default" };
  const toastId = "toast_import_table_error";

  const { isLoading, isError, error, data, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      ["imports", searchOptions, partnerId],
      ({ pageParam = 1 }) =>
        fetchImports({
          pageParam,
          pageSize: 20,
          searchOptions,
          urlParams: { partnerId },
        }),
      {
        refetchInterval: 300000,
      }
    );

  useEffect(() => {
    setAreOptionsDisabled(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setTimeout(() => {
      fetchStoredScrollPosition("search-imports-scrollable", "IMPORTS");
    }, 100);
  }, []);

  const queryClient = useQueryClient();

  const invalidateQuery = () => queryClient.invalidateQueries("imports");

  // * #####################################
  // * Loading and Error States
  // * #####################################

  if (isLoading) {
    return (
      <CustomLoader containerClasses={styles.Tab_Content_Flex_Container} />
    );
  }

  // * To prevent duplicate toast messages
  if (isError) {
    toast.error(getErrorMessage(error, "Cannot fetch imports"), {
      toastId: toastId,
    });
  }

  // * Manipulate paginated response data
  const imports = data?.pages.flatMap((page) => page.data.results);
  const lastPage = data?.pageParams.at(-1) || 1;

  // * #####################################
  // * No data state
  // * #####################################
  if (!imports || imports?.length === 0) {
    return (
      <NoDataFound
        {...{
          title: "No imports found for requested filter",
          wrapperClasses: styles.Tab_Content_Flex_Container,
        }}
      />
    );
  }

  return (
    <section
      id="search-imports-scrollable"
      className={tableClasses}
      ref={importTableRef}>
      <InfiniteScroll
        style={containerClasses}
        loader={<h4 className="Font_H8">Loading...</h4>}
        next={fetchNextPage}
        dataLength={lastPage * imports?.length}
        hasMore={hasNextPage}
        onScroll={(event) => storeScrollPosition(event, "IMPORTS")}
        scrollableTarget="search-imports-scrollable">
        <table className="Table overflow-auto">
          <ImportTableHeader {...{ searchOptions, currentDashboard }} />
          <ImportTableBody
            {...{
              imports,
              searchOptions,
              currentDashboard,
              refreshImports: invalidateQuery,
              hidePromoteLink,
            }}
          />
        </table>
      </InfiniteScroll>
    </section>
  );
};

export default ImportTable;
