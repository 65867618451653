import * as countTableStyles from "components/common/CountTable/CountTable.module.scss";
import * as styles from "./FileError.module.scss";

import ErrorContainer from "./ErrorContainer";
import { FILE_STATUS_SUBMISSION_PROBLEM } from "common/constants";
import { capitalCase } from "change-case";
import classNames from "classnames";

const FileError = ({ importDetail }) => {
  const { errors, import_status: importStatus } = importDetail;

  const sectionClasses = classNames(
    countTableStyles.Count_Table_Card_Container,
    styles.File_Error_Wrapper,
    "mb-2"
  );

  return (
    <section className={sectionClasses}>
      <FileErrorHeader
        {...{
          importStatus,
        }}
      />
      {!!errors?.file_problem ? (
        <FileProblemError {...{ errors, importStatus }} />
      ) : (
        <SheetErrors {...{ errors }} />
      )}
    </section>
  );
};

export default FileError;

//***************************/
//*****Local Components******/
//***************************/

const FileErrorHeader = ({ importStatus }) => {
  return (
    <header className="d-flex justify-content-between pb-4">
      <span className="Font_H4 Font_Title_Color">
        {importStatus === FILE_STATUS_SUBMISSION_PROBLEM
          ? "Submission Problems"
          : "Technical Error"}
      </span>
    </header>
  );
};

const FileProblemError = ({ errors }) => {
  return (
    <section className={styles.File_Problem_Error_Container}>
      <span className="Font_H7 Font_Subtitle_Color">
        {errors?.file_problem || "Some problem occurred while uploading file"}
      </span>
    </section>
  );
};

const SheetErrors = ({ errors }) => {
  const { sheets } = errors || {};

  return (
    <>
      {sheets ? (
        Object.entries(sheets)?.map(([sheetName, errors], index) => {
          return Array.isArray(errors) ? (
            <SheetError
              {...{
                sheetName: "",
                errors: {
                  [capitalCase(sheetName)]: errors,
                },
              }}
              key={index}
            />
          ) : (
            <SheetError {...{ sheetName, errors }} key={index} />
          );
        })
      ) : (
        <span className="Font_H7 Font_Subtitle_Color">
          Something went wrong
        </span>
      )}
    </>
  );
};

const SheetError = ({ sheetName, errors }) => {
  console.log(sheetName, errors);
  return (
    <section className={styles.Sheet_Error_Container}>
      {sheetName && (
        <header className="Font_H6 Font_Subtitle_Color">
          <span className="Font_H5 Font_Title_Color">Sheet name |</span>
          &nbsp;{sheetName}
        </header>
      )}
      <>
        {Object.entries(errors)?.map(([errorName, errorValue], index) => (
          <ErrorContainer key={index} {...{ errorName, errorValue }} />
        ))}
      </>
    </section>
  );
};
