import { axiosInstance, getAuthorizationHeaders } from "common/API/api";
import { DEFAULT_PAGE_SIZE } from "common/constants";

import { getCleanDataURLs } from "common/utils/apiUrls";

export const getCleanDataId = ({ partnerId, loanId }) => {
  const url = getCleanDataURLs("getCleanDataIdURLs", {
    partnerId,
  });

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: {
      loan_id: loanId,
    },
  });
};

export const getCleanDataRecord = ({ cleanDataId, dataFilterId }) => {
  const url = getCleanDataURLs("getCleanDataRecordURLs", {
    cleanDataId,
  });

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: {
      data_filter_id: dataFilterId,
    },
  });
};

export const getCleanDataUpdateImports = async ({
  pageParam,
  searchOptions,
  pageSize = DEFAULT_PAGE_SIZE,
}) => {
  const url = getCleanDataURLs("getCleanDataUpdateImportURLs");

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: {
      page: pageParam,
      page_size: pageSize,
      ...searchOptions,
    },
  });
};
