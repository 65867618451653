export const VIEWER_USER_ROLE = "VIEWER";
export const CARBON_TECH_USER_ROLE = "CARBON-TECH";
export const DATA_TEAM_USER_ROLE = "DATA-TEAM";
export const ADMIN_USER_ROLE = "ADMIN";

// Export Report Status
export const WAITING = "WAITING";
export const PROCESSING = "PROCESSING";
export const FAILED = "FAILED";

export const EXPORT_REPORT_STATUS = {
  WAITING: "Waiting (in queue)",
  PROCESSING: "Processing",
  FAILED: "Failed",
  COMPLETED: "Completed",
};

// Record Status
export const DATA_PHASE = {
  p1: "Clean (Proposed and Confirmed)",
  p2: "Proposed Only",
  p3: "Confirmed Only",
  p4: "Primary",
  p5: "Excluded",
};

export const DATA_PHASE_BREADCRUMB = {
  [DATA_PHASE["p1"]]: "Clean",
  [DATA_PHASE["p2"]]: "Proposed",
  [DATA_PHASE["p3"]]: "Confirmed",
  [DATA_PHASE["p4"]]: "Primary (Raw)",
  [DATA_PHASE["p5"]]: "Excluded",
};

export const DEFAULT_PAGE_SIZE = 6;

// * #####################################
// * Uploaded Files Constants
// * #####################################

export const FILE_STATUS_PROBLEMS_FOUND = "Problems Found";
export const FILE_STATUS_SUBMISSION_PROBLEM = "Submission Problem";
export const FILE_STATUS_ALL_VALID = "All Valid";
export const FILE_STATUS_PROMOTED = "Promoted";
export const FILE_STATUS_PROMOTION_FAILED = "Promotion Failed";
export const FILE_STATUS_QUEUED_FOR_PROMOTION = "Queued for Promotion";
export const FILE_STATUS_FAILED = "Failed";
export const FILE_STATUS_ERASED = "Erased";
export const UPLOADED_FILE_STATUS = {
  s1: "in_progress",
  s2: "promoted",
  s3: "erased",
  s4: "submission_problem",
  s5: "problems_found",
  s6: "clean_data_updated",
};

// * Default values for date range picker
export const DEFAULT_START_DATE = null;
export const DEFAULT_END_DATE = new Date("");

// * Product Types Full name constants
export const PRODUCT_TYPES = {
  pt1: "Water Purification System",
  pt2: "Improved Cookstove",
  pt3: "Solar Lighting System",
  pt4: "Solar Lamp",
  pt5: "Solar Home Lighting System",
};

// * Values of records tab of search detail page to store in session storage to preserve
export const SCROLL_POSITION = {
  PARTNERS: "scrollPositionPartners",
  RECORDS: "scrollPositionRecords",
  IMPORTS: "scrollPositionImports",
  SEARCHES: "scrollPositionSearch",
  EXPORTS: "scrollPositionExports",
  PROJECT_ACTIVITIES: "scrollPositionProjectActivites",
  MONGOlIAN_PROGRAM: "scrollPositionMongolainProgram",
  DLIGHT_KENYA: "scrollPositionDlightKenya",
  DLIGHT_UGANDA: "scrollPositionDlightUganda",
};

/* The `PAGE_IDENTIFIERS` constant is an object that defines different page identifiers as keys and
their corresponding values. These identifiers are used to represent different pages in the
application. */
export const PAGE_IDENTIFIERS = {
  PARTNERS: "partners",
  RECORDS: "records",
  IMPORTS: "imports",
  SEARCHES: "searches",
  EXPORTS: "exports",
  PROJECT_ACTIVITIES: "project-activities",
  MONGOlIAN_PROGRAM: "mongolian-program",
  DLIGHT_KENYA: "dlight-kenya",
  DLIGHT_UGANDA: "dlight-uganda",
  CLEAN_DATA_IMPORT_LIST: "clean-data-import-list",
};

export const SEARCH_ID_OF_RECORD = "searchId";
export const SORTING_ORDER = "sortOrder";
export const CURRENT_ORDER_FIELD = "currentOrderField";
export const ORDERING_FIELD = "orderField";

export const ALLOWED_EXTENSIONS = /(\.xlsx|\.xls|\.csv)$/i;
export const ALLOWED_TYPES =
  /(text\/csv|application\/vnd\.openxmlformats-officedocument\.spreadsheetml\.sheet| application\/vnd\.ms-excel)$/i;
