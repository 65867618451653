import ListTopSection from "components/common/ListTopSection/ListTopSection";
import NavigateButton from "components/common/NavigateButton/NavigateButton";
import SiteAdminLinks from "./SiteAdminLinks/SiteAdminLinks";

const AdminTab = () => {
  return (
    <>
      <ListTopSection
        {...{
          actionChildren: (
            <NavigateButton
              {...{
                title: "Clean Data Update List",
                route: "CleanDataImportList",
              }}
            />
          ),
        }}
      />

      <section className="d-flex justify-content-evenly">
        <SiteAdminLinks
          {...{
            title: "Site Administration",
          }}
        />
      </section>
    </>
  );
};
export default AdminTab;
