import React from "react";
import * as styles from "components/common/SortableTableHeader/SortableTableHeader.module.scss";

const CleanDataUpdateImportHeader = ({ showCreatedBy = true }) => {
  const isHeaderSticky = true;

  return (
    <thead
      className={`Thead ${isHeaderSticky ? styles.Table_Header_Sticky : ""}`}>
      <tr>
        <th className="Th">Partner</th>
        <th className="Th">Label</th>
        <th className="Th">Status</th>
        <th className="Th">Imported At</th>
        <th className="Th">Updated Columns</th>
        {showCreatedBy ? <th className="Th">Created By</th> : null}
        <th className="Th">Action</th>
      </tr>
    </thead>
  );
};
export default React.memo(CleanDataUpdateImportHeader);
