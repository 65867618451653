import NoDataFound from "components/common/NoDataFound/NoDataFound";
import * as summaryStyles from "../ResultSummary.module.scss";

const SuccessResult = ({ wrapperClasses = "" }) => {
  return (
    <section className={wrapperClasses}>
      <section className={summaryStyles.First_Level_Summary_Wrapper}>
        <div className={summaryStyles.First_Level_Title}>
          <span>
            <strong>Clean Data Update Import</strong>
          </span>
        </div>
      </section>
      <NoDataFound
        wrapperClasses={summaryStyles.No_Result_Summary_Found}
        {...{
          title: "No error found. The clean data updated successfully.",
        }}
      />
    </section>
  );
};

export default SuccessResult;
