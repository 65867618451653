import FileNameLink from "components/common/FileInfoCard/FileNameLink";
import ImportTableItem from "../ImportTableItem/ImportTableItem";
import { formatDateTime } from "common/utils/dateAndTimeUtils";

const OverviewDetail = ({
  label,
  fileName,
  createdAt,
  filePath,
  type,
  partner_name,
  importStatus,
  createdBy,
  updatedColumns,
}) => {
  const formattedColumns = updatedColumns.join(", ");
  return (
    <>
      {type === "main" && (
        <ImportTableItem itemKey="Partner" itemValue={partner_name} />
      )}
      <FileNameLink {...{ keyName: "File Name", filePath, fileName }} />
      <ImportTableItem itemKey="File Label" itemValue={label} />
      <ImportTableItem
        itemKey="Uploaded at"
        itemValue={formatDateTime(createdAt)}
      />
      <ImportTableItem itemKey="Uploaded by" itemValue={createdBy} />

      <ImportTableItem itemKey="Import status" itemValue={importStatus} />
      <ImportTableItem itemKey="Updated Columns" itemValue={formattedColumns} />
    </>
  );
};

export default OverviewDetail;
