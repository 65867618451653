import CollapsibleTopSection from "components/common/CollapsibleTopSection/CollapsibleTopSection";
import CollapsibleFilterChildren from "./CollapsibleFilterChildren/CollapsibleFilterChildren";
import { useState } from "react";
import Actions from "./CollapsibleFilterChildren/Action";
import ScrollToTop from "components/common/ScrollToTop/ScrollToTop";
import { CleanDataUpdateImportTable } from "./CleanDataUpdateImportTable";
import { getCleanDataUpdateImports } from "common/API/cleanDataAPIs";

const CleanDataImportList = () => {
  const [searchOptions, setSearchOptions] = useState({});
  const [toggleFilters, setToggleFilters] = useState(false);

  const { partner, ...remainingSearchOptions } = searchOptions;

  return (
    <>
      <CollapsibleTopSection
        {...{
          searchOptions,
          actionChildren: (
            <Actions
              {...{ selectedPartnerId: searchOptions?.partner?.value }}
            />
          ),
          collapsibleFilterChildren: (
            <CollapsibleFilterChildren
              {...{ searchOptions, setSearchOptions }}
            />
          ),
          toggleFilters,
          setToggleFilters,
        }}
      />
      <CleanDataUpdateImportTable
        {...{
          searchOptions: {
            ...remainingSearchOptions,
            partner_id: partner?.value,
          },
          setAreOptionsDisabled: () => {},
          fetchImports: getCleanDataUpdateImports, // TODO: Integrate getCleanDate API
          hidePromoteLink: true,
          extraClass: toggleFilters ? "Table_Height3" : "Table_Height1",
        }}
      />
      {/* <table className="Table overflow-auto">
        <CleanDataUpdateImportTableHeader />
        <CleanDataUpdateImportBody
          {...{
            imports,
            showCreatedBy: false,
          }}
        />
      </table> */}
      <ScrollToTop />
    </>
  );
};

export default CleanDataImportList;
