import { getErrorMessage } from "./errorUtils";
import { getPresignedURL } from "common/API/api";
import isArray from "lodash/isArray";
import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";
import { toast } from "react-toastify";
import transform from "lodash/transform";
import { SCROLL_POSITION } from "common/constants";

export function getUploadFileFormData(file, presigned_post) {
  let formData = new FormData();

  for (var field in presigned_post.fields) {
    formData.append(field, presigned_post.fields[field]);
  }

  formData["key"] = presigned_post.fields["key"].replace(
    // eslint-disable-next-line no-template-curly-in-string
    "${filename}",
    file.name
  );

  formData.append("file", file);

  return formData;
}

export const convertSnakeCaseToTitleCase = (str) => {
  // convert given word to Title case from Snake case
  return str
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "NA";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const getFilePathFromPresignedPost = (presigned_post, fileName) => {
  return presigned_post.fields["key"].replace(
    // eslint-disable-next-line no-template-curly-in-string
    "${filename}",
    fileName
  );
};

export const getPillsArrayFromObject = (obj) => {
  if (!!!obj) {
    return { obj: {}, array: [] };
  }
  const keys = Object.keys(obj);
  return {
    obj,
    array: keys
      .map(
        (key) =>
          obj[key] && {
            label: key,
            value: obj[key],
          }
      )
      .filter(Boolean),
  };
};

export const redirectToViewResult = (id) => {
  window.open(`/searches/${id}`, "_self");
};

export const redirectToViewImport = (id) => {
  window.open(`/imports/${id}`, "_self");
};

export const redirectToViewExport = (id) => {
  window.open(`/exports/${id}`, "_self");
};

/**
 * Return array of IDs given array of dropdown options.
 * @param arr - The array of objects that you want to get the id from.
 * @returns Array of IDs.
 */
const getIdFromArray = (arr) => {
  return Array.isArray(arr) ? arr.map((item) => item.value) : [];
};

export const prepareFilterAndGroupBySchema = ({
  partner,
  dataPhase,
  state,
  branch,
  productType,
  productModel,
  earliestDate,
  latestDate,
  monitoringPeriod,
  projectActivityAssigned,
  column1,
  column2,
}) => {
  let finalProductModels = getIdFromArray(productModel);
  let finalBranches = getIdFromArray(branch);
  let finalProjectActivities = getIdFromArray(projectActivityAssigned);

  finalProductModels = finalProductModels?.length > 0 ? finalProductModels : "";
  finalBranches = finalBranches?.length > 0 ? finalBranches : "";
  finalProjectActivities =
    finalProjectActivities?.length > 0 ? finalProjectActivities : "";

  return {
    filter_ids: {
      common_filters: {
        partner: (partner?.value || "").toString(),
        data_phase: dataPhase?.value || "",
      },
      additional_filters: {
        product_type: productType?.id || "",
        product_model: finalProductModels,
        state: state?.value || "",
        branch: finalBranches,
        earliest_date: earliestDate,
        latest_date: latestDate,
        monitoring_period: monitoringPeriod?.value || "",
        project_activity_id: finalProjectActivities,
      },
    },
    group_by_ids: {
      column1: column1?.value || "",
      column2: column2?.value || "",
    },
  };
};

export const downloadFileFromS3 = async (objectPath) => {
  if (!objectPath) {
    toast.error("File path is not available");
    return;
  }

  try {
    const response = await getPresignedURL(objectPath);
    const presignedFileUrl = response?.data?.url;

    if (!presignedFileUrl) {
      throw new Error("Error while getting file url.");
    }

    window.open(presignedFileUrl, "_blank");
  } catch (error) {
    console.log(error);
    toast.error(getErrorMessage(error, "Error while getting file url."));
  }
};

/**
 * Returns formatted available capacity with percentage
 * @param projectActivity - Project activity object for a product type.
 * @returns formatted HTML with capacity and percentage.
 */
export const showAvailableCapacity = (projectActivity) => {
  if (projectActivity?.available_capacity === null) return "-";

  return (
    <>
      <span className="pe-1">
        {abbreviateNumber(projectActivity?.available_capacity)}
      </span>
      <span className="Font_H9 Font_Title_Color">
        {projectActivity?.available_capacity_percentage !== null
          ? `(${projectActivity?.available_capacity_percentage})`
          : ""}
      </span>
    </>
  );
};

/**
 * It takes a number and returns a string that is the number in a compact format
 * @param number - The number to be abbreviated.
 * @returns string.
 */
export const abbreviateNumber = (number) => {
  return typeof number === "undefined" || Number.isNaN(number)
    ? "-"
    : new Intl.NumberFormat("en-US", {
        notation: "compact",
        maximumFractionDigits: 1,
      }).format(number);
};

/**
 * Find difference between two objects
 * @param  {object} origObj - Source object to compare newObj against
 * @param  {object} newObj  - New object with potential changes
 * @return {object} differences
 */
export const difference = (origObj, newObj) => {
  const changes = (newObj, origObj) => {
    let arrayIndexCounter = 0;

    return transform(newObj, (result, value, key) => {
      if (!isEqual(value, origObj[key])) {
        let resultKey = isArray(origObj) ? arrayIndexCounter++ : key;
        result[resultKey] =
          isObject(value) && isObject(origObj[key])
            ? changes(value, origObj[key])
            : value;
      }
    });
  };
  return changes(newObj, origObj);
};

/**
 * It takes an array of objects and returns an array of objects with the particular keys and values
 * of the keys being the values of the keys you specify
 * @param array - The array of objects you want to convert to dropdown options.
 * @param valueField - The field in the array that you want to use as the value for the dropdown.
 * @returns An array of objects with the value and name properties.
 */
export const convertArrayToDropdownOptions = (array, valueField) => {
  const dropdownArray = [];
  {
    valueField &&
      array.forEach((item) => {
        const { id: value, [valueField]: name } = item;
        dropdownArray.push({ value, name });
      });
  }

  /* Sorting the array of objects with its name alphabetically. */
  dropdownArray.sort((a, b) => {
    return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
  });

  return dropdownArray;
};

/* Optimize an object with truthy values only */
export const objectWithTruthyValues = (obj) => {
  const newObj = {};
  Object.entries(obj).map(([key, value]) => {
    if (!!value) newObj[key] = value;
  });
  return newObj;
};

/**
 * It stops the event from bubbling up the DOM tree
 * @param e - The event object.
 */
export const stopPropagation = (e) => {
  e.stopPropagation();
};

export const removeStoredScrollPosition = (page) => {
  const positionKey = SCROLL_POSITION[page];
  sessionStorage.removeItem(positionKey);
};

export const storeScrollPosition = (event, page) => {
  const positionKey = SCROLL_POSITION[page];
  const position = event?.srcElement?.scrollTop;
  sessionStorage.setItem(positionKey, position);
};

export const fetchStoredScrollPosition = (refId, page) => {
  const positionKey = SCROLL_POSITION[page];
  const position = sessionStorage.getItem(positionKey);
  const recordContainer = document?.getElementById(refId);
  if (page === "RECORDS") {
    removeStoredScrollPosition("search");
  }
  recordContainer?.scrollTo(0, position);
};

/**
 * Below functions helps to clear specific filters stored in the sessionStorage based on a given page identifier.
 *
 * @param pageIdentifier - The pageIdentifier parameter is a unique identifier for the page or
 * component that is calling these functions. It is used to create unique keys for the sessionStorage
 * items that are being removed.
 */
export const clearImportFilters = (pageIdentifier) => {
  sessionStorage.removeItem(`${pageIdentifier}selectedPartner`);
  sessionStorage.removeItem(`${pageIdentifier}selectedImportType`);
  sessionStorage.removeItem(`${pageIdentifier}importStatus`);
  sessionStorage.removeItem(`${pageIdentifier}startDate`);
  sessionStorage.removeItem(`${pageIdentifier}endDate`);
};

export const clearSearchesFilters = (pageIdentifier) => {
  sessionStorage.removeItem(`${pageIdentifier}selectedPartner`);
  sessionStorage.removeItem(`${pageIdentifier}DataPhase`);
  sessionStorage.removeItem(`${pageIdentifier}startDate`);
  sessionStorage.removeItem(`${pageIdentifier}endDate`);
};

export const clearExportFilters = (pageIdentifier) => {
  sessionStorage.removeItem(`${pageIdentifier}selectedPartner`);
  sessionStorage.removeItem(`${pageIdentifier}DataPhase`);
  sessionStorage.removeItem(`${pageIdentifier}ExportType`);
  sessionStorage.removeItem(`${pageIdentifier}startDate`);
  sessionStorage.removeItem(`${pageIdentifier}endDate`);
};

export const clearMongoliaFilters = (pageIdentifier) => {
  sessionStorage.removeItem(`${pageIdentifier}Product`);
  sessionStorage.removeItem(`${pageIdentifier}ProjectActivities`);
  sessionStorage.removeItem(`${pageIdentifier}startDate`);
  sessionStorage.removeItem(`${pageIdentifier}endDate`);
};

export const clearKenyaFilters = (pageIdentifier) => {
  sessionStorage.removeItem(`${pageIdentifier}ProductModel`);
  sessionStorage.removeItem(`${pageIdentifier}CPAFilter`);
  sessionStorage.removeItem(`${pageIdentifier}startDate`);
  sessionStorage.removeItem(`${pageIdentifier}endDate`);
};

export const clearUgandaFilters = (pageIdentifier) => {
  sessionStorage.removeItem(`${pageIdentifier}ProductModel`);
  sessionStorage.removeItem(`${pageIdentifier}CPAFilter`);
  sessionStorage.removeItem(`${pageIdentifier}startDate`);
  sessionStorage.removeItem(`${pageIdentifier}endDate`);
};

export const clearCleanDataUpdateImportFiters = (pageIdentifier) => {
  sessionStorage.removeItem(`${pageIdentifier}selectedPartner`);
  // sessionStorage.removeItem(`${pageIdentifier}selectedImportType`);
  sessionStorage.removeItem(`${pageIdentifier}importStatus`);
  sessionStorage.removeItem(`${pageIdentifier}startDate`);
  sessionStorage.removeItem(`${pageIdentifier}endDate`);
};
