// import { getPillsArrayFromObject } from "common/utils/utils";

import { formatDateTime } from "common/utils/dateAndTimeUtils";
import HyperLink from "components/common/HyperLink/HyperLink";
import EyeIconImg from "images/Eye.svg";
import { useNavigate } from "react-router-dom";

export const CleanDataUpdateImportBody = ({ imports }) => {
  const navigate = useNavigate();

  const handleOnClick = (import_id) => {
    console.log("clicked on record.", import_id);
    navigate(`/clean-data-import/${import_id}`);
  };
  console.log(imports);

  return (
    <tbody className="Tbody">
      {imports.map((import_data, index) => {
        console.log(import_data, index);
        return (
          <tr
            className="Tr"
            key={import_data?.id || index}
            onClick={() => handleOnClick(import_data?.id)}>
            <td className="Td">{import_data?.partner_name}</td>
            <td className="Td">{import_data?.label}</td>
            <td className="Td">{import_data?.import_status}</td>
            <td className="Td">
              {formatDateTime(import_data?.created_at) || "-"}
            </td>
            <td className="Td">
              {import_data?.updated_columns.map((column, index) => {
                return (
                  <span key={index}>
                    {column}
                    {index < import_data.updated_columns.length - 1 && ", "}
                  </span>
                );
              })}
            </td>
            <td className="Td">{import_data?.created_by}</td>
            <td className="Td d-flex gap-4">
              <HyperLink
                iconSrc={EyeIconImg}
                altText="eye"
                text="View Details"
                // onClick={() => redirectToViewExport(export_report?.id)}
                // disabled={disabled}
              />
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};
