import PlusIcon from "images/circle_plus.svg";
import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";
import Button from "components/common/Button/Button";
import { useNavigate } from "react-router-dom";

const Actions = () => {
  const navigate = useNavigate();

  return (
    <Button
      {...{
        buttonType: ButtonTypes.PRIMARY_SMALL,
        buttonContentType: ButtonContentTypes.ICON_TEXT,
        onClick: () => {
          navigate("/clean-data-update-import");
        },
        isDisabled: false,
      }}
      className="me-3">
      <img src={PlusIcon} alt="plus" />
      <span>Clean Data Update Import</span>
    </Button>
  );
};

export default Actions;
