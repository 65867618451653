import ReactSelectDropdown from "components/common/ReactSelectDropdown/ReactSelectDropdown";
import { getErrorMessage } from "common/utils/errorUtils";
import { getPartnersList } from "common/API/fieldAPIs";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import { useEffect } from "react";

const PartnerDropdown = ({
  setSearchOptions,
  selectedPartner,
  className = "",
  pageIdentifier,
  ...attributeProps
}) => {
  const {
    data: response,
    isError,
    error,
  } = useQuery("partners", getPartnersList);

  // * To prevent duplicate toast messages
  const toastId = "toast_partners_dropdown_error";
  if (isError) {
    toast.error(getErrorMessage(error, "Cannot fetch Partners for filters"), {
      toastId: toastId,
    });
  }

  const handlePartnerChange = (partner) => {
    setSearchOptions((value) => ({ ...value, partner }));
    if (
      pageIdentifier !== undefined &&
      (partner !== undefined || partner !== null)
    ) {
      sessionStorage.setItem(
        `${pageIdentifier}selectedPartner`,
        JSON.stringify(partner)
      );
    }
  };

  const partnerList = response?.data?.payload;

  // Fetches and applies a stored partner filter.
  useEffect(() => {
    const storedSelectedPartner = sessionStorage.getItem(
      `${pageIdentifier}selectedPartner`
    );
    if (storedSelectedPartner) {
      handlePartnerChange(JSON.parse(storedSelectedPartner));
    }
  }, [pageIdentifier]);

  return (
    <ReactSelectDropdown
      {...{
        isAsync: false,
        selectedOption: selectedPartner,
        setSelectedOption: handlePartnerChange,
        options: partnerList,
        customWidth: "200px",
        className,
        placeholder: "Partner",
        ...attributeProps,
      }}
    />
  );
};

export default PartnerDropdown;
