import * as styles from "styles/Common.module.scss";

import CustomLoader from "../CustomLoader/CustomLoader";
import { ExportTableBody } from "./ExportTableBody";
import ExportTableHeader from "./ExportTableHeader";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFound from "../NoDataFound/NoDataFound";
import { getErrorMessage } from "common/utils/errorUtils";
import { toast } from "react-toastify";
import { useEffect, createRef } from "react";
import { useInfiniteQuery } from "react-query";
import classNames from "classnames";
import {
  storeScrollPosition,
  fetchStoredScrollPosition,
} from "common/utils/utils";

const ExportTable = ({
  searchOptions,
  setAreOptionsDisabled,
  partnerId,
  fetchExports,
  showCreatedBy,
  extraClass = "",
}) => {
  const exportTableRef = createRef();

  const tableclasses = classNames(
    "Table_Wrapper",
    styles[extraClass],
    styles.List_Scroll_Table
  );
  const containerClasses = { overflow: "default" };

  const { isLoading, isError, error, data, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      ["exports", searchOptions, partnerId],
      ({ pageParam = 1 }) =>
        fetchExports({
          pageParam,
          pageSize: 20,
          searchOptions,
          urlParams: { partnerId },
        }),
      {
        refetchInterval: Infinity,
      }
    );

  // * Get the first time data based on search option
  useEffect(() => {
    setAreOptionsDisabled(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setTimeout(() => {
      fetchStoredScrollPosition("scrollable-exports", "EXPORTS");
    }, 100);
  }, []);

  // * #####################################
  // * Loading and No data states
  // * #####################################
  if (isLoading) {
    return (
      <CustomLoader containerClasses={styles.Tab_Content_Flex_Container} />
    );
  }

  // * To prevent duplicate toast messages
  const toastId = "toast_export_table_error";
  if (isError) {
    toast.error(getErrorMessage(error, "Cannot fetch searches"), {
      toastId: toastId,
    });
  }

  // * Manipulate paginated response data
  const exports = data?.pages.flatMap((page) => page.data.results);
  const lastPage = data?.pageParams.at(-1) || 1;

  if (!exports || exports?.length === 0) {
    return (
      <NoDataFound
        {...{
          title: "No exports found for requested filter",
          wrapperClasses: styles.Tab_Content_Flex_Container,
        }}
      />
    );
  }
  return (
    <section
      id="scrollable-exports"
      className={tableclasses}
      ref={exportTableRef}>
      <InfiniteScroll
        style={containerClasses}
        loader={<h4 className="Font_H8">Loading...</h4>}
        next={fetchNextPage}
        dataLength={lastPage * exports.length}
        hasMore={hasNextPage}
        onScroll={(event) => storeScrollPosition(event, "EXPORTS")}
        scrollableTarget="scrollable-exports">
        <table className="Table overflow-auto">
          <ExportTableHeader {...{ showCreatedBy }} />
          <ExportTableBody
            {...{
              exports,
              showCreatedBy,
            }}
          />
        </table>
      </InfiniteScroll>
    </section>
  );
};

export default ExportTable;
