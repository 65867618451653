import * as countTableStyles from "components/common/CountTable/CountTable.module.scss";
import * as styles from "./ResultSummary.module.scss";

import classNames from "classnames";
import NoResultSummary from "./NoResultSummary/NoResultSummary";
import SuccessResult from "./NoResultSummary/SuccessResult";
import React from "react";
import FileError from "../FileError/FileError";

const ResultSummary = ({ importDetail }) => {
  const { errors: resultSummary } = importDetail;
  const { import_status: importStatus } = importDetail;

  const sectionClasses = classNames(
    countTableStyles.Count_Table_Card_Container,
    styles.Result_Summary_Wrapper,
    "mb-2"
  );

  let dupes_and_overlaps = resultSummary?.dupes_and_overlaps || {};

  if (!resultSummary && importStatus != "Clean Data Updated") {
    return <NoResultSummary {...{ wrapperClasses: sectionClasses }} />;
  } else if (!resultSummary) {
    return <SuccessResult {...{ wrapperClasses: sectionClasses }} />;
  } else if (
    resultSummary &&
    importStatus == "Submission Problem" &&
    resultSummary?.file_problem
  ) {
    return <FileError {...{ importDetail }} />;
  } else {
    return (
      <section className={sectionClasses}>
        {<HeaderComponent />}
        {/* Render DataVerificationProblems if unverified_mec_data exists */}

        {resultSummary?.sheets &&
          Object.values(resultSummary.sheets).some(
            (sheetData) => sheetData.unverified_mec_data
          ) && (
            <DataVerificationProblems
              unverifiedData={
                Object.values(resultSummary.sheets)[0]?.unverified_mec_data
              }
            />
          )}

        {/* Render DataValueProblems if other keys like bad_values exist */}

        {Object.keys(resultSummary?.sheets || {}).some((key) =>
          Object.keys(resultSummary.sheets[key] || {}).some(
            (subKey) => subKey !== "unverified_mec_data"
          )
        ) && <DataValueProblems data={transformDataValue(resultSummary)} />}

        {/* Render DuplicateProblems if dupes_and_overlaps exists */}
        {Object.keys(dupes_and_overlaps).length > 0 && (
          <DuplicateProblems
            data={transformDuplicateData(dupes_and_overlaps)}
          />
        )}
      </section>
    );
  }
};
export default ResultSummary;

//***************************/
//*****Local Components******/
//***************************/

const transformDuplicateData = (json1) => {
  const json2 = {};

  for (const duplicateType in json1) {
    const { Records, duplicates } = json1[duplicateType];

    const groupingField =
      duplicateType === "hhid_overlap" ? "user_id" : "loan_id";

    json2[groupingField] = {
      count: Records,
      details: [],
    };

    duplicates.forEach((duplicateGroup) => {
      // Sort the duplicate group by row_number, ascending
      duplicateGroup.sort((a, b) => a.row_number - b.row_number);

      const db_record = duplicateGroup.find(
        (record) => record.model === "clean_data"
      );
      const reportRecords = duplicateGroup.filter(
        (record) => record.model === "clean_data_report"
      );
      const row_number = reportRecords[0]?.row_number || null;

      const file_duplicate_row = reportRecords
        .slice(1) // Skip the first record
        .map((record) => record.row_number);

      const duplicateDetail = {
        row_number: row_number,
        id_value: db_record
          ? db_record[groupingField]
          : duplicateGroup[0][groupingField],
        dbDuplicateMECId: db_record?.id ?? "-",
        fileDuplicateRow: file_duplicate_row,
      };

      json2[groupingField].details.push(duplicateDetail);
    });
  }

  return json2;
};

const HeaderComponent = () => {
  return (
    <div className={styles.Header}>
      <h2>Data Problems Found</h2>
      <p>No changes were made to the clean data records</p>
    </div>
  );
};

const DuplicateProblems = ({ data }) => {
  const thirdLevelData = Object.entries(data).map(([key, value]) => ({
    errorType: key,
    count: value.count,
    rows: value.details,
  }));
  const headerTitle = [
    "Row Number",
    "Duplicate ID Value",
    "DB Duplicate MEC ID",
    "File Duplicate Row",
  ];

  return (
    <FirstLevelSummary
      first_level_title="Duplicate Problems"
      first_Level_SubTitle="Some exact ID duplicates were found">
      <SecondLevelSummary
        second_level_title="Duplicate Field"
        second_level_subtitle="Count of Rows">
        <ThirdLevelSummary
          thirdLevelData={thirdLevelData}
          title={headerTitle}
          isDuplicateProblems={true}
        />
      </SecondLevelSummary>
    </FirstLevelSummary>
  );
};

// Helper function to truncate long text
const truncateText = (text, maxLength = 15) => {
  if (text === null || text === undefined) {
    return "-";
  }
  return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
};

const DataVerificationProblems = ({ unverifiedData }) => {
  const headerTitle = ["Field", "DB Value", "Spreadsheet Value"];

  const thirdLevelData = Object.entries(unverifiedData).map(([key, value]) => ({
    rowNo: key,
    fields: [
      ...new Set(
        value.flatMap((item) => item.column_name) // Collect `column_name` directly
      ),
    ],
    rows: value,
  }));

  return (
    <FirstLevelSummary
      first_level_title="Data Verification Problems"
      first_Level_SubTitle="Some non-updating fields did not match the database value for the given MEC ID">
      <SecondLevelSummary
        second_level_title="Row No"
        second_level_subtitle="Fields">
        <ThirdLevelSummary
          thirdLevelData={thirdLevelData}
          title={headerTitle}
          isVerificationProblem={true}
        />
      </SecondLevelSummary>
    </FirstLevelSummary>
  );
};

// Transform function to prepare the data
const transformDataValue = (jsonData) => {
  const result = [];
  for (const [, issues] of Object.entries(jsonData.sheets)) {
    for (const [issueType, issueData] of Object.entries(issues)) {
      if (issueType === "unverified_mec_data") {
        continue;
      }
      if (Array.isArray(issueData)) {
        result.push({
          errorType: issueType,
          count: issueData.length,
          rows: issueData,
        });
      } else if (typeof issueData === "object") {
        for (const [field, rows] of Object.entries(issueData)) {
          result.push({
            errorType: `bad_values: ${field}`,
            count: rows.length,
            rows,
          });
        }
      } else if (issueType === "missing_columns") {
        result.push({
          errorType: "missing_columns",
          count: issueData.length,
          columns: issueData,
        });
      }
    }
  }
  return result;
};

const FirstLevelSummary = ({
  first_level_title,
  first_Level_SubTitle,
  children,
}) => {
  return (
    <section className={styles.First_Level_Summary_Wrapper}>
      <div className={styles.First_level}>
        <div className={styles.First_Level_Title}>
          <span>
            <strong>{first_level_title}</strong>
          </span>
        </div>
        <strong className={styles.First_Level_SubTitle}>
          {first_Level_SubTitle}
        </strong>
      </div>
      {children}
    </section>
  );
};

const SecondLevelSummary = ({
  second_level_title,
  second_level_subtitle,
  children,
}) => {
  return (
    <section className={styles.Second_Level_Summary_Wrapper}>
      <div className={styles.Second_Level_Title}>
        <span>{second_level_title}</span>
        <span>{second_level_subtitle}</span>
      </div>
      {children}
    </section>
  );
};

const ThirdLevelSummary = ({
  thirdLevelData,
  title,
  isDuplicateProblems = false,
  isVerificationProblem = false,
}) => {
  return (
    <section className={styles.Third_Level_Summary_Wrapper}>
      {thirdLevelData.map((item, index) => (
        <div key={index}>
          <div
            className={
              isVerificationProblem
                ? styles.Third_Level_Verification_Title
                : styles.Third_Level_Title
            }>
            {isVerificationProblem ? (
              <>
                <span>{item.rowNo}</span>
                <span>{item.fields.join(",  ")}</span>
              </>
            ) : (
              <>
                <span>{item.errorType}</span>
                <span>{item.count}</span>
              </>
            )}
          </div>
          {item.rows && (
            <>
              <div className={styles.Third_Level_SubTitle}>
                {title &&
                  title.map((items, index) => (
                    <span key={index}>
                      {item.errorType === "missing_columns"
                        ? "Column Name"
                        : items}
                    </span>
                  ))}
              </div>
              {item.rows.map((row, rowIndex) => (
                <div
                  key={rowIndex}
                  className={
                    isVerificationProblem
                      ? styles.Third_Level_Verification_Summary
                      : styles.Third_Level_Summary
                  }>
                  {isDuplicateProblems ? (
                    <>
                      <span>{row.row_number}</span>
                      <span>{row.id_value}</span>
                      <span>{row.dbDuplicateMECId}</span>
                      <span>{row.fileDuplicateRow.join(", ") || "-"}</span>
                    </>
                  ) : isVerificationProblem ? (
                    <>
                      <span>{row.column_name}</span>
                      <span>{truncateText(row.db_value)}</span>
                      <span>{truncateText(row.updated_value)}</span>
                    </>
                  ) : (
                    <span>{row}</span>
                  )}
                </div>
              ))}
            </>
          )}
        </div>
      ))}
    </section>
  );
};

// Component to display transformed error data
const DataValueProblems = ({ data }) => {
  const headerTitle = ["Row Numbers"];

  return (
    <FirstLevelSummary
      first_level_title="Data Value Problems"
      first_Level_SubTitle="Some updating fields had bad values">
      <SecondLevelSummary
        second_level_title="Error Type"
        second_level_subtitle="Count of Rows">
        <ThirdLevelSummary thirdLevelData={data} title={headerTitle} />
      </SecondLevelSummary>
    </FirstLevelSummary>
  );
};
