import { capitalCase } from "change-case";
import isObject from "lodash/isObject";
import { toast } from "react-toastify";

/**
 * It takes in an object of errors and returns a formatted string of errors
 * @param [data] - The data object that contains the errors.
 * @returns A function that takes in an object and returns a string.
 */
export const parseSerializerErrors = (data = {}, getMessageArray = false) => {
  try {
    if (!isObject(data)) return "";

    let formattedError = "";

    if (getMessageArray) {
      return Object.values(data?.errors || data).map((errors) =>
        errors.join(". ")
      );
    }

    Object.entries(data).map(([field, errors]) => {
      formattedError += `${capitalCase(field)} : ${errors.join(". ")} \n `;
    });

    return formattedError;
  } catch (err) {
    return false;
  }
};

/**
 * Returns error message string based on error response
 * @param error - The error object that was returned from the API call.
 * @param [defaultMessage=Something went wrong!] - The default message to return if
 * the error doesn't have a message.
 * @returns Error message string.
 */

export const getErrorMessage = (
  error,
  defaultMessage = "Something went wrong!",
  doParseSerializerErrors = false,
  getMessageArray = false
) => {
  return (
    (doParseSerializerErrors &&
      parseSerializerErrors(error?.response?.data, getMessageArray)) ||
    error?.response?.data?.message ||
    error?.response?.data?.detail ||
    error?.message ||
    defaultMessage
  );
};

export const handleFileUploadToastMessages = (response) => {
  if (response?.status === 200 || response?.status === 201) {
    toast.success(response?.data?.message || "Successfully uploaded!");
  } else {
    let message = "Something went wrong";
    try {
      message =
        response?.data?.errors?.label?.toString() ||
        response?.data?.errors?.country?.toString() ||
        response?.data?.errors?.file_name?.toString() ||
        response?.data?.errors?.file_path?.toString() ||
        response?.data?.message?.toString();
    } catch (error) {
      console.log(error);
    }
    console.error(message);
  }
};
