import * as commonStyles from "styles/Common.module.scss";

import { FILE_STATUS_FAILED } from "common/constants";

import CustomLoader from "components/common/CustomLoader/CustomLoader";
import ImportDetailLeftSection from "./ImportDetailLeftSection/ImportDetailLeftSection";
import ResultSummary from "./ResultSummary/ResultSummary";
import FileError from "./FileError/FileError";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

import { PAGE_IDENTIFIERS } from "common/constants";
import { clearImportFilters } from "common/utils/utils";
import { useAutoClearFilter } from "common/hooks/useAutoClearFitlers";
import { getCleanDataUpdateImportDetails } from "common/API/importAPIs";

const CleanDataImportDetails = () => {
  const { cleanDataUpdateImportId } = useParams();

  /* To automatically clear import filters for a given page identifier. */
  const pageIdentifier = PAGE_IDENTIFIERS.IMPORTS;
  useAutoClearFilter(pageIdentifier, clearImportFilters);

  const {
    isLoading,
    data: response,
    isError,
    error,
    // refetch,
  } = useQuery(
    ["clean-data-update-import-details", cleanDataUpdateImportId],
    () => getCleanDataUpdateImportDetails(cleanDataUpdateImportId)
  );
  console.log("id:", cleanDataUpdateImportId);
  console.log("response:", response);
  if (isLoading) {
    return (
      <CustomLoader
        containerClasses={commonStyles.Full_Height_Flex_Container}
      />
    );
  }

  if (isError) {
    console.error(error);
  }

  const importDetail = response?.data?.payload;

  const hasFileErrors = [FILE_STATUS_FAILED].includes(
    importDetail?.import_status
  );
  return (
    <>
      <section className="pb-3 d-flex flex-wrap-reverse align-items-end">
        {importDetail && (
          <>
            <ImportDetailLeftSection {...{ importDetail }} />
            {!hasFileErrors ? (
              <ResultSummary {...{ importDetail }} />
            ) : (
              <FileError {...{ importDetail }} />
            )}
          </>
        )}
      </section>
    </>
  );
};

export default CleanDataImportDetails;
