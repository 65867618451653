import ReactSelectDropdown from "components/common/ReactSelectDropdown/ReactSelectDropdown";
import { useEffect } from "react";
import { cleanDataUpdateImportStatuses } from "./CleanDataUpdateImportStatusDropdown.constant";

const CleanDataUpdateImportStatusDropdown = ({
  cleanDataUpdateImportStatus,
  setCleanDataUpdateImportStatus,
  setSearchOptions,
  className,
  pageIdentifier,
}) => {
  // Stores import status filter for import page.
  useEffect(() => {
    const storedImportStatus = sessionStorage.getItem(
      `${pageIdentifier}importStatus`
    );
    if (storedImportStatus) {
      setCleanDataUpdateImportStatus(JSON.parse(storedImportStatus));
    }
  }, [pageIdentifier]);

  // Fetches and applies a stored import type filter for import page.
  useEffect(() => {
    setSearchOptions((value) => ({
      ...value,
      status: cleanDataUpdateImportStatus?.value,
    }));
    if (
      cleanDataUpdateImportStatus !== undefined ||
      cleanDataUpdateImportStatus !== null
    ) {
      sessionStorage.setItem(
        `${pageIdentifier}importStatus`,
        JSON.stringify(cleanDataUpdateImportStatus)
      );
    }
  }, [cleanDataUpdateImportStatus, setSearchOptions, pageIdentifier]);
  return (
    <ReactSelectDropdown
      {...{
        isAsync: false,
        selectedOption: cleanDataUpdateImportStatus,
        setSelectedOption: setCleanDataUpdateImportStatus,
        options: cleanDataUpdateImportStatuses,
        customWidth: "200px",
        className,
        placeholder: "Import Status",
      }}
    />
  );
};

export default CleanDataUpdateImportStatusDropdown;
