import { formatDate, formatDateTime } from "common/utils/dateAndTimeUtils";

import ImportTableBodyActions from "./ImportTableBodyActions";
import { VIEWER_USER_ROLE } from "common/constants";
import { getUserProperty } from "common/utils/authenticationUtils";
import { useNavigate } from "react-router-dom";

const ImportTableBody = ({
  imports,
  searchOptions,
  currentDashboard,
  refreshImports,
  hidePromoteLink = false,
}) => {
  const isViewerRoleUser = getUserProperty("user_role") === VIEWER_USER_ROLE;

  const navigate = useNavigate();

  const handleOnClick = (importId) => {
    navigate(`/imports/${importId}`);
  };

  return (
    <tbody className="Tbody">
      {imports.map((importFile, index) => {
        return (
          <tr
            key={importFile?.id || index}
            className="Tr"
            onClick={() => handleOnClick(importFile?.id)}>
            {currentDashboard === "partner" ? (
              <td className="Td">{importFile?.user}</td>
            ) : (
              <td className="Td">{importFile?.partner_name}</td>
            )}

            <td className="Td">{importFile?.import_type}</td>
            <td className="Td">{importFile?.label}</td>
            <td className="Td">{importFile?.import_status}</td>
            <td className="Td">
              {formatDateTime(importFile?.created_at) || "-"}
            </td>
            {searchOptions?.status === "promoted" && (
              <td className="Td">
                {formatDate(importFile?.promoted_on) || "-"}
              </td>
            )}
            <td className="Td">{importFile?.total_records}</td>
            <td className="Td">{importFile?.clean_records}</td>
            <td className="Td">{importFile?.total_error_records}</td>
            <td className="Td">{importFile?.excluded_records}</td>
            <td className="Td d-flex gap-1 gap-xxl-4 flex-wrap">
              <ImportTableBodyActions
                {...{
                  refreshImports,
                  hidePromoteLink,
                  isViewerRoleUser,
                  importFile,
                }}
              />
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default ImportTableBody;
