import Overview from "../Overview/Overview";

const ImportDetailLeftSection = ({ importDetail }) => {
  console.log("importDetails:", importDetail);
  return (
    <section className="d-flex flex-row flex-xxl-column overflow-auto">
      <Overview
        {...{
          details: importDetail,
          title: "Overview",
          type: "main",
        }}
      />
    </section>
  );
};

export default ImportDetailLeftSection;
