import * as styles from "./ExportDetail.module.scss";

import { useEffect, useState } from "react";

import CustomLoader from "components/common/CustomLoader/CustomLoader";
import { ExportDetailLeftSection } from "./ExportDetailLeftSection/ExportDetailLeftSection";
import ExportDetailRightSection from "./ExportDetailRightSection/ExportDetailRightSection";
import classNames from "classnames";
import { getExportReportDetails } from "common/API/filterAndReportListAPI";
import { useParams } from "react-router-dom";

import { PAGE_IDENTIFIERS } from "common/constants";
import { clearExportFilters } from "common/utils/utils";
import { useAutoClearFilter } from "common/hooks/useAutoClearFitlers";

const ExportDetail = () => {
  const { exportId } = useParams();
  const [exportReport, setExportReport] = useState();
  const [isLoading, setIsLoading] = useState(true);

  /* To automatically clear exports filters for a given page identifier. */
  const pageIdentifier = PAGE_IDENTIFIERS.EXPORTS;
  useAutoClearFilter(pageIdentifier, clearExportFilters);

  // * #####################################
  // * Effects
  // * #####################################
  useEffect(() => {
    fetchExportReportDetails(setExportReport, setIsLoading, exportId);
  }, []);

  const filterResult = {
    pivotTable: exportReport?.pivot_table,
    pivotTableSummary: exportReport?.pivot_table_summary,
    totalCount: exportReport?.total_count,
    lastUpdatedCountAt: exportReport?.updated_at,
  };

  const exportDetails = {
    reportType: exportReport?.report_type,
    updatedAt: exportReport?.updated_at,
    userFirstName: exportReport?.user_first_name,
    userLastName: exportReport?.user_last_name,
    filename: exportReport?.file_name,
    reportPath: exportReport?.report_path,
    fileSize: exportReport?.file_size,
  };

  if (isLoading) {
    const loaderContainerClasses = classNames(
      styles.Export_Detail_Container,
      "align-items-center justify-content-center"
    );
    return <CustomLoader containerClasses={loaderContainerClasses} />;
  }

  return (
    <main className={styles.Export_Detail_Container}>
      <ExportDetailLeftSection
        {...{ dataFilter: exportReport?.data_filter, exportDetails }}
      />
      <ExportDetailRightSection
        {...{
          filterId: exportReport?.data_filter?.id,
          filterResult,
          reportPath: exportReport?.report_path,
          fileSize: exportReport?.file_size,
        }}
      />
    </main>
  );
};

export default ExportDetail;

const fetchExportReportDetails = async (
  setExportReport,
  setIsLoading,
  exportId
) => {
  try {
    const exportReport = await getExportReportDetails(exportId);
    console.log("exportReport", exportReport);
    setExportReport(exportReport);
    setIsLoading(false);
  } catch (error) {
    console.error(error);
  }
};
